.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.Upload {
    align-items: center;
    justify-content: center;
    font-family: "Lato", sans-serif;
}

.upload-container {
    display: flex;
    flex-direction: column;
    /* Stack children vertically */
    align-items: center;
    /* Center children horizontally */
    justify-content: center;
    /* Center children vertically */
    height: 100%;
    /* Take full height of the parent, adjust as needed */
}

/*****************************************
  upload button styles
******************************************/
.file-upload {
    position: relative;
    display: inline-block;
}

.upload-body {
    color: #5B6F82;
    font-size: 1rem;
}

.upload-container.file-upload {
    display: flex;
    flex-direction: column;
    /* Stack children vertically */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    height: 100%;
    /* Adjust based on your layout needs */
}

.file-upload__label {
    display: block;
    padding: 1em 2em;
    color: #fff;
    background: #222;
    border-radius: .4em;
    transition: background .3s;
    font-family: "Lato", sans-serif;

    &:hover {
        cursor: pointer;
        background: #000;
    }
}

.file-upload__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width: 0;
    height: 100%;
    opacity: 0;
}