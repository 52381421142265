h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    clear: both;
    color: #202B36;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.insights-container,
.suggestions-container {
    opacity: 0;
    /* Start with containers hidden */
    animation: fadeInUp 0.5s ease-out forwards;
}

.insights-container {
    animation-delay: 1s;
    /* Delay for suggestions to appear first */
}

.suggestions-container {
    animation-delay: 0.5s;
}


.Mainimg {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ4MCIgaGVpZ2h0PSI2NTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICAgIDxwYXRoIGQ9Ik03MzEuMjA3IDY0OS44MDJDOTM1LjQ4NCA2NDIuMTQgMTQ4MCAzMzcuMzI1IDE0ODAgMTgwLjg4OGMwLTE1Ni40MzgtMzA5Ljc0NC0zNi4wNTUtNzIwLTM2LjA1NVMwLTE3NC40ODMgMCAxMzUuMTQ0YzAgMzA5LjYyNyA1MjYuOTMgNTIyLjMyIDczMS4yMDcgNTE0LjY1OHoiIGZpbGw9IiNGNkY4RkEiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
}

.Upload {
    align-items: center;
    justify-content: center;
    font-family: "Lato", sans-serif;
}

.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Use 100vh to make it take the full viewport height */
}

.transcript-button {
    background-color: #09f;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: "Lato", sans-serif;
    margin-left: 10%;
}

.center-container-insights {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
    /* Use 100vh to make it take the full viewport height */
    /* only use 80% of width */
    margin-left: 10%;
    margin-right: 10%;
}

.insights-text {
    font-size: 1.2rem;
    font-weight: 400;
    color: #5E6164;
    font-family: "Roboto", sans-serif;
}

.upload-container {
    display: flex;
    flex-direction: column;
    /* Stack children vertically */
    align-items: center;
    /* Center children horizontally */
    justify-content: center;
    /* Center children vertically */
    height: 100%;
    /* Take full height of the parent, adjust as needed */
}

/*****************************************
  upload button styles
******************************************/
.file-upload {
    position: relative;
    display: inline-block;
}

.upload-body {
    color: #5B6F82;
    font-size: 1rem;
}

.upload-container.file-upload {
    display: flex;
    flex-direction: column;
    /* Stack children vertically */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    height: 100%;
    /* Adjust based on your layout needs */
}

.file-upload__label {
    display: block;
    padding: 1em 2em;
    color: #fff;
    background: #222;
    border-radius: .4em;
    transition: background .3s;
    font-family: "Lato", sans-serif;

    &:hover {
        cursor: pointer;
        background: #000;
    }
}

.file-upload__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width: 0;
    height: 100%;
    opacity: 0;
}