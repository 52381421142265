h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    clear: both;
    color: #202B36;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.sop-container {
    opacity: 0;
    /* Start with containers hidden */
    animation: fadeInUp 0.5s ease-out forwards;
    animation-delay: 0.5s;
}

.Mainimg {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ4MCIgaGVpZ2h0PSI2NTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICAgIDxwYXRoIGQ9Ik03MzEuMjA3IDY0OS44MDJDOTM1LjQ4NCA2NDIuMTQgMTQ4MCAzMzcuMzI1IDE0ODAgMTgwLjg4OGMwLTE1Ni40MzgtMzA5Ljc0NC0zNi4wNTUtNzIwLTM2LjA1NVMwLTE3NC40ODMgMCAxMzUuMTQ0YzAgMzA5LjYyNyA1MjYuOTMgNTIyLjMyIDczMS4yMDcgNTE0LjY1OHoiIGZpbGw9IiNGNkY4RkEiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
}

.sop-center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Use 100vh to make it take the full viewport height */
}

.sop-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 75vh; */
    /* Use 100vh to make it take the full viewport height */
    /* only use 80% of width */
    margin-left: 10%;
    margin-right: 10%;
}
